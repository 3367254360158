<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import customTable from "@/components/paginate-table.vue";
import mobileView from "../../../components/mobile-view.vue";
import { detectScreen } from "@/_helpers";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, customTable, mobileView },
  page: {
    title: "Data Kategori",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "#/",
        },
        {
          text: "Produk",
          href: "#/produk",
        },
        {
          text: "Kategori",
          active: true,
        },
      ],
      screenWidth: 0,
      dataKey: "cloth-category",
      apiUrl: process.env.VUE_APP_API_BASE_URL + "cloth-categories",
      columns: [
        {
          prop: "sku_code",
          label: "Kode SKU",
          width: "200",
        },
        {
          prop: "name",
          label: "Nama",
          width: "200",
        },
        {
          prop: "description",
          label: "Description",
        },
        {
          prop: "created_at",
          label: "Tgl Dibuat",
          date: true,
        },
        {
          prop: "updated_at",
          label: "Tgl Diperbarui",
          date: true,
        },
      ],
    };
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
  },
  methods: {
    onScreenResize() {
      this.screenWidth = detectScreen();
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout v-if="screenWidth >= 860">
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <custom-table
          ref="dataTable"
          :with-form-add="true"
          :form-url="'/produk/kategori/tambah'"
          :with-filter-name="true"
          :with-delete="true"
          :with-print="false"
          :with-edit="true"
          :data-url="apiUrl"
          :data-key="dataKey"
          :data-columns="columns"
          title-filter="Filter Berdasarkan Nama Kategori Pakaian"
          filter-with="name"
        />
      </b-card-body>
    </b-card>
  </Layout>
  <mobileView
    v-else
    ref="dataTable"
    title-filter="Filter Berdasarkan Nama Kategori Pakaian"
    :with-filter-text="true"
    :card-category="true"
    :card-default="false"
    :form-url="'/produk/kategori/tambah'"
    skeleton-radius="5px"
    skeleton-height="100px"
    :cardLoad="5"
    :data-url="apiUrl"
  />
</template>
